import React, { useEffect, useState } from "react";
import ProjectData from '../../config';
let projectName = "Martik"
const {
	Name,
	Logo,
	logoUrl,
	backgroundColor,
	buttonsColor,
	buttonsBorders,
	inputsColor,
	inputsBorders,
	outbuttonsColor,
	Borders,
	outFont,
	innerFont,
	Routers
} = ProjectData[window.location.host.split('.')[0] != undefined 
? window.location.host.split('.')[0] == "app" 
? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1] 
: window.location.host.split('.')[0] : "Martik"]
export function Alert() {
	return (
		<div>
			<dialog id="success_modal_alert" style={{ height: "min-content", width: "280px", borderRadius: "10px", background: backgroundColor, border: "none", position: "absolute", margin: "auto" }}>
				<form method="dialog" >
					<button style={{ cursor: "pointer", position: "absolute", top: "10px", right: "10px", background: backgroundColor, borderRadius: "50%", color: outFont, border: "none", height: "30px", width: "30px" }}>✕</button>
					<div style={{ height: "200px", width: "100%", display: "grid", }}>
						<div className="box bounce-2" style={{ height: "150px", width: "150px", background: "url(" + logoUrl + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "80%", margin: "auto" }}>
						</div>
					</div>
					<h3 style={{ fontWeight: "bold", fontSize: "24px", textAlign:"center", color: "#22c55e" }}></h3>
					<p style={{ fontSize: "14px", color: outFont }}></p>
				</form>
			</dialog>
			<dialog id="error_modal_alert" style={{ height: "min-content", width: "280px", borderRadius: "10px", background: backgroundColor, border: "none", position: "absolute", margin: "auto" }}>
				<form method="dialog" className="modal-box">
					<button style={{ cursor: "pointer", position: "absolute", top: "10px", right: "10px", background: backgroundColor, borderRadius: "50%", color: outFont, border: "none", height: "30px", width: "30px" }}>✕</button>
					<div style={{ height: "200px", width: "100%", display: "grid" }}>
						<div className="box bounce-2" style={{ height: "150px", width: "150px", background: "url(" + logoUrl + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "80%", margin: "auto" }}>
						</div>
					</div>
					<h3 style={{ fontWeight: "bold", fontSize: "24px",textAlign:"center", color: "#dc2626" }}></h3>
					<p style={{ fontSize: "14px", color: outFont }}></p>
				</form>
			</dialog>
			<dialog id="loading_modal_alert" style={{  height: "min-content", width: "280px", borderRadius: "10px", background: backgroundColor, border: "none", position: "absolute", margin: "auto" }}>
				<form method="dialog" className="modal-box">
					<button style={{ cursor: "pointer", position: "absolute", top: "10px", right: "10px", background: backgroundColor, borderRadius: "50%", color: outFont, border: "none", height: "30px", width: "30px" }}>✕</button>
					<div style={{ height: "200px", width: "100%", display: "grid", }}>
						<div className="box bounce-2" style={{ height: "150px", width: "150px", background: "url(" + logoUrl + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "80%", margin: "auto" }}>
						</div>
					</div>
					<h3 style={{ fontWeight: "bold", fontSize: "24px",textAlign:"center", color: outFont }}></h3>
					<p style={{ fontSize: "14px", color: outFont }}></p>
				</form>
			</dialog>
		</div>
	);
}
export function Emit(Title, Desc, Type) {

	let _AlertData = {}
	_AlertData.Title = Title
	_AlertData.Desc = Desc
	_AlertData.Type = Type

	if (_AlertData.Type == 'success') {
		window.success_modal_alert.showModal()
		window.error_modal_alert.close()
		window.loading_modal_alert.close()

		document.getElementById("success_modal_alert").getElementsByTagName("h3")[0].innerHTML = _AlertData.Title
		document.getElementById("success_modal_alert").getElementsByTagName("p")[0].innerHTML = _AlertData.Desc
	} else {
		if (_AlertData.Type == 'error') {
			window.success_modal_alert.close()
			window.error_modal_alert.showModal()
			window.loading_modal_alert.close()

			document.getElementById("error_modal_alert").getElementsByTagName("h3")[0].innerHTML = _AlertData.Title
			document.getElementById("error_modal_alert").getElementsByTagName("p")[0].innerHTML = _AlertData.Desc
		} else {
			window.success_modal_alert.close()
			window.error_modal_alert.close()
			window.loading_modal_alert.showModal()

			document.getElementById("loading_modal_alert").getElementsByTagName("h3")[0].innerHTML = _AlertData.Title
			document.getElementById("loading_modal_alert").getElementsByTagName("p")[0].innerHTML = _AlertData.Desc
		}
	}



}
export default { Alert, Emit };