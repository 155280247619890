import React from 'react';
import ProjectData from '../../config';
let projectName = "Martik"
const {
  Name,
  Logo,
  backgroundColor,
  buttonsColor,
  buttonsBorders,
  inputsColor,
  inputsBorders,
  outbuttonsColor,
  Borders,
  outFont,
  innerFont,
  Routers
} = ProjectData[window.location.host.split('.')[0] != undefined 
? window.location.host.split('.')[0] == "app" 
? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1] 
: window.location.host.split('.')[0] : "Martik"]

function InputBlock(props) {
  return (
    <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", gap: "5px", margin: "auto" }}>
      <p style={{ margin: "auto", marginLeft: "0%", textAlign: "right", color: outFont, fontSize: "14px" }}>{props.title}</p>
      {
        props.variavel == undefined
          ? <input
            id={props.id == undefined ? "" : props.id}
            type={props.type == undefined ? "text" : props.type}
            onChange={(e) => { props.setvariavel(e.target.value) }}
            placeholder={props.place}
            style={{ fontSize: "15px", outline: "none", color: innerFont, textAlign: "left", height: "35px", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", border: inputsBorders, background: inputsColor, borderRadius: "2px", paddingLeft: "10px" }}></input>
          : <input
            id={props.id == undefined ? "" : props.id}
            type={props.type == undefined ? "text" : props.type}
            onChange={(e) => { props.setvariavel(e.target.value) }}
            placeholder={props.place}
            value={props.variavel}
            style={{ fontSize: "15px", outline: "none", color: innerFont, textAlign: "left", height: "35px", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", border: inputsBorders, background: inputsColor, borderRadius: "2px", paddingLeft: "10px" }}></input>
      }

      <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: outFont, fontSize: "10px" }}>{props.desc}</p>
    </div>
  );
}

export default InputBlock;