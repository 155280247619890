import Web3 from 'web3';
import { ethers } from 'ethers';

import { getAccount, prepareWriteContract, waitForTransaction, writeContract, getNetwork } from 'wagmi/actions'
import AppContext from '../../framework/helpers/AppContext';
import { Emit } from "../../components/Alert/Alert";
import { useAccount } from 'wagmi';
const publicrpc = {
  
    8453: "https://base.meowrpc.com"
}

function Interact({ content }) {

    const { address, connector } = useAccount();

    const aweb3 = () => {
        try {
            const w3 = new Web3(publicrpc[getID()]);
            return w3.eth;
        } catch (error) {
            const rpc = "https://base.meowrpc.com"
            const w3 = new Web3(rpc);
            return w3.eth;
        }
    }
    const netdata = getNetwork()
    const web3 = aweb3();
    async function Tokencontract(abi, address) {
        try {
            const web3 = new Web3(publicrpc[getID()]);
            return new web3.eth.Contract(abi, address);
        } catch (error) {
            const web3 = new Web3("https://base.meowrpc.com");
            return new web3.eth.Contract(abi, address);
        }

    }

    async function gasTX(func, _info, ...args) {
        const data = await func(...args).estimateGas(_info)
        return data;
    }
    function getID() {
        try {
            const netdata = getNetwork()
            return netdata.chain == undefined ? 8453 : netdata.chain.id;
        } catch (error) {
            return 8453
        }

    }
    async function callTX(func, _info, ...args) {
        const data = await func(...args).call(_info)
        return data;
    }

    async function sendTX(func, callback, _value, _info, ...args) {
        const data = await func(...args).send(_info)
        return data;
    }
    const nSendTXSwap = (_contractA, _ABI, NetId, funcN, _value, GWEI, ...args) => new Promise(async function (myResolve, myReject) {
        Emit("AGUARDANDO...", "Aguardando informaçoes", 'wait')
        try {
            const approveTxUnsigned = {}
            approveTxUnsigned.address = _contractA;
            approveTxUnsigned.abi = _ABI;
            approveTxUnsigned.functionName = funcN;
            approveTxUnsigned.args = args;
            approveTxUnsigned.account = getAccount()['address'];
            approveTxUnsigned.value = _value;
            console.log(approveTxUnsigned)
            if (GWEI == 0) { } else {
                approveTxUnsigned.gasPrice = GWEI * 1000000000
            }
            const { request } = await prepareWriteContract(approveTxUnsigned)
            const { hash } = await writeContract(request)
            await waitForTransaction({ hash: hash });
            myResolve(hash)
            Emit("SUCESSO", "Operação concluida com sucesso!", 'success')
        } catch (error) {
            myReject(error)
            Emit("ERRO!", JSON.stringify({
                Erro: error.message
            }), 'error')
        }
    });
    const nSendTX = (_contractA, _ABI, NetId, funcN, _value, GWEI, ...args) => new Promise(async function (myResolve, myReject) {
        Emit("WAIT...", "Waiting for information", 'wait')
        try {

            const approveTxUnsigned = {}
            approveTxUnsigned.address = _contractA;
            approveTxUnsigned.functionName = funcN;
            approveTxUnsigned.args = args;
            approveTxUnsigned.account = getAccount()['address'];
            approveTxUnsigned.value = _value;
            approveTxUnsigned.abi = _ABI;
            /*     if (GWEI == 0) { } else {
                     approveTxUnsigned.gasPrice = GWEI * 1000000000
                 }*/
            //  console.log(approveTxUnsigned)
            const { request } = await prepareWriteContract(approveTxUnsigned)
            //console.log(request)
            const { hash } = await writeContract(request)
            await waitForTransaction({ hash: hash });
            myResolve(hash)

            Emit("SUCCESS", "Operation completed successfully!", 'success')
        } catch (error) {

            myReject(JSON.stringify({
                Erro: error.message
            }))

            Emit("ERROR!", JSON.stringify({
                Erro: error.message
            }), 'error')
        }
    });
    const nSendCT = (bytecode, abi, _value, ...args) => new Promise(async function (myResolve, myReject) {
        Emit("AGUARDANDO...", "Aguardando informaçoes", 'wait')
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const signer = provider.getSigner()
            const factory = new ethers.ContractFactory(abi, bytecode, signer);
            const approveTxUnsigned = {}
            approveTxUnsigned.from = getAccount()['address'];
            approveTxUnsigned.value = _value;

            const contract = await factory.deploy(...args)
            //  console.log(contract)
            await contract.deployed()

            const netdata = getNetwork()
            const id = netdata.chain == undefined ? 8453 : netdata.chain.id;
            const url = id == 8453 ? "https://bscscan.com" : 'https://testnet.bscscan.com'
            myResolve(contract)
            Emit("SUCESSO", "Operação concluida com sucesso!", 'success')
            window.open(url + "/address/" + contract.address)
        } catch (error) {
            myReject(error)
            Emit("ERRO!", JSON.stringify({
                Erro: error.message
            }), 'error')
        }
    });
    const interactContract = {
        account: address,
        Tokencontract,
        gasTX,
        callTX,
        sendTX,
        nSendTX,
        web3,
        aweb3,
        nSendCT,
        getID,
        nSendTXSwap,
        publicrpc
    };
    /*
    account,
    provider,
    chain,
    chains,
    web3,
    aweb3,
    Tokencontract,
    gasTX,
    getID,
    callTX,
    sendTX,
    nSendTX,
    nSendCT
*/
    return (
        <AppContext.Provider value={interactContract}>
            {content}
        </AppContext.Provider>
    );

}
export default Interact;