export default {
    "presalebobcat": {
        Name: "Bobcat Legacy",
        logoUrl: `${window.location.protocol}/favicon.ico`,
        Logo: <img
            src={ `${window.location.protocol}/favicon.ico`}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,
        backgroundColor: "url( 'https://static.wixstatic.com/media/9e6fb3_2e0ba9a49b474635afe670abbc7a9b07~mv2.png/v1/fill/w_1293,h_610,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/9e6fb3_2e0ba9a49b474635afe670abbc7a9b07~mv2.png ' ) center center / " + `${parseInt((window.innerHeight / 9) * 21)}px ${window.innerHeight}px`+" no-repeat" ,//https://static.wixstatic.com/media/9e6fb3_2e0ba9a49b474635afe670abbc7a9b07~mv2.png/v1/fill/w_1293,h_610,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/9e6fb3_2e0ba9a49b474635afe670abbc7a9b07~mv2.png
        buttonsColor: "#161a1e00",
        modalsColor: "#161a1e10",
        outbuttonsColor: "#fff",
        buttonsBorders: "1px solid #ffffff20",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #ffffff20",
        Borders: "#ffffff20",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#222",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',
              
            ],
        menudata: [
            {
                permit: false,//Trade
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: "",
        info: {
            title: "BobCatLegacy",
            desc: "Bringing hearts together through the legacy of Bob and James. Join the BobCatLegacy revolution and become part of a community committed to compassion, hope, and love.",
            buyon: "",
            name: "BobCatLegacy",
            symbol: "BobCatLegacy",
            telegram: "",
            twitter: "",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0xA4de20856640d59A146393ac8CcF3F8e2133DF33",
                    ccFees: [
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.0
                    ],

                    tokenTo50Fee: "0x116526135380E28836C6080f1997645d5A807FAE"
                }
            },
            "swap": {
                web3: {
                    Router: "0xA1a39C041221765eb52BaEA2FDBbc77aa92c0dF3",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xC1b4AcC0E4420369BE3C634777F890e42007327B",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                }
            },
            "nftpools": {
                factory: "0x449d637816DAe66c8707D7fdC5EfCe2F7540531B",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                }
            }

        }

    }
}