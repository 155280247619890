import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Connection from './hooks/wallet/connection';
import './pages/home.css';
import './pages/farm.css';
import { ethers } from 'ethers';
import { ConnectButton } from '@rainbow-me/rainbowkit';
//screens
import Home from './pages/newhome';


import { useNavigate } from "react-router-dom";
import { Alert } from './components/Alert/Alert';
import ProjectData from './config';
let projectName = "Martik"
const {
  Name,
  Logo,
  backgroundColor,
  buttonsColor,
  buttonsBorders,
  modalsColor,
  inputsColor,
  inputsBorders,
  outbuttonsColor,
  Borders,
  outFont,
  topFont,
  innerFont,
  innerBFont,
  menudata,
  Routers,
  logoUrl,
  info
} = ProjectData[window.location.host.split('.')[0] != undefined
  ? window.location.host.split('.')[0] == "app"
    ? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1]
    : window.location.host.split('.')[0] : "Martik"]
const Web3 = require('web3-eth');
const w = window.innerWidth;



let sideli = {
  height: "50px",
  width: "100%",
  display: "grid",
  cursor: "pointer",
  paddingLeft: "10px",
  paddingRight: "10px",

}
let sidelis = {
  height: "50px",
  width: "100%",
  margin: "auto",
  textAlign: "left",
  display: "grid",
  gridTemplateColumns: "40px auto 25px",
  fontWeight: "bold",
  color: topFont == undefined ? outFont : topFont,
  fontSize: "13px",
  cursor: "pointer"

}
let sidelistop = {
  height: "70px",
  width: "100%",
  minWidth: "120px",
  margin: "auto",
  textAlign: "left",
  display: "flex",
  fontWeight: "bold",
  color: topFont == undefined ? outFont : topFont,
  fontSize: "13px",
  cursor: "pointer"

}
let logo = Logo

const WButton = () => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus ||
            authenticationStatus === 'authenticated');

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              'style': {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <div id="wallet" className="Wallet" style={{ background: buttonsColor, border: buttonsBorders, color: topFont == undefined ? outFont : topFont }} onClick={openConnectModal}>
                    <svg style={{ height: "20px", width: "20px", margin: "auto", }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet" viewBox="0 0 16 16">
                      <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z" />
                    </svg>
                    <h1>Connect Wallet</h1>
                  </div>
                );
              }

              if (chain.unsupported) {
                return (
                  <div id="wallet" className="Wallet" style={{ background: buttonsColor, border: buttonsBorders, color: topFont == undefined ? outFont : topFont }} onClick={openChainModal}>
                    <svg style={{ height: "20px", width: "20px", margin: "auto", }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet" viewBox="0 0 16 16">
                      <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z" />
                    </svg>
                    <h1>Wrong network</h1>
                  </div>
                );
              }

              return (
                <div style={{ display: 'flex', gap: 12 }}>
                  <div onClick={openChainModal} style={{ cursor: "pointer", borderRadius: "5px ", padding: "7px", margin: "auto", background: buttonsColor, border: buttonsBorders, color: topFont == undefined ? outFont : topFont, display: "grid" }}>
                    <img
                      alt={chain.name ?? 'Chain icon'}
                      src={chain.iconUrl}
                      style={{ borderRadius: "5px ", width: "20px", height: "20px", margin: "auto" }}></img>
                  </div>

                  <div id="wallet" className="Wallet" style={{ background: buttonsColor, border: buttonsBorders, color: topFont == undefined ? outFont : topFont }} onClick={openAccountModal}>
                    <svg style={{ height: "20px", width: "20px", margin: "auto", }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet" viewBox="0 0 16 16">
                      <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z" />
                    </svg>
                    <h1>
                      {account.displayName}<p />
                      {account.displayBalance
                        ? ` (${account.displayBalance})`
                        : ''}</h1>
                  </div>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};


const Main = (props) => {
  const navigate = useNavigate();
  const [prices, setprice] = useState(0);
  const [menu, setmenu] = useState(w > 700);
  const [seconds, setSeconds] = useState(0);
 

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = logoUrl;
    document.title = (Name).toUpperCase() + getPATH()
  }, [window.location.pathname]);

  const getPATH = () => {
    if (window.location.pathname.includes("/")) {
      let f = window.location.pathname.split("/")
      let string = ""
      for (let index = 0; index < f.length; index++) {
        string = string.concat((" " + f[index]).toUpperCase())
      }
      return string
    } else {
      return ""
    }
  }

  
  function nextblock(accountBalancemctTB, d) {
    return accountBalancemctTB == undefined ? 0 : parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(2)
  }

  function menustyle(bool) {
    return <div style={{
      height: "70px",
      width: "100%",
      margin: "auto",
      overflow: "hidden",
      display: "grid",
      gridTemplateColumns: "150px auto 200px",

    }}>
      <div style={{
        height: "50px",
        width: "100%",
        overflow: "hidden",
        display: "grid",
        gridTemplateColumns: "50% 50%",
        cursor: "pointer",
        margin: "auto"
      }}>
        <svg onClick={() => { setmenu(!menu) }} style={{
          height: "30px",
          width: "30px",
          margin: "auto",
          fill: topFont == undefined ? outFont : topFont
        }} viewBox="0 0 24 24" width="24px" color="textSubtle" xmlns="http://www.w3.org/2000/svg" className="sc-bdnxRM cSawQi"><path d="M4 18H15C15.55 18 16 17.55 16 17C16 16.45 15.55 16 15 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18ZM4 13H12C12.55 13 13 12.55 13 12C13 11.45 12.55 11 12 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3 7C3 7.55 3.45 8 4 8H15C15.55 8 16 7.55 16 7C16 6.45 15.55 6 15 6H4C3.45 6 3 6.45 3 7ZM20.3 14.88L17.42 12L20.3 9.12C20.69 8.73 20.69 8.1 20.3 7.71C19.91 7.32 19.28 7.32 18.89 7.71L15.3 11.3C14.91 11.69 14.91 12.32 15.3 12.71L18.89 16.3C19.28 16.69 19.91 16.69 20.3 16.3C20.68 15.91 20.69 15.27 20.3 14.88Z" /></svg>
        {logo}
      </div>
      <div></div>
      <div style={{
        display: "grid", gridTemplateColumns: "auto", height: "min-content", width: "100%", margin: "auto",
        borderRadius: "10px"
      }}>
        <WButton />
      </div>
    </div>
  }

  function checkpathtitle(e) {
    let a = window.location.pathname.toString().split("/");

    if (window.location.pathname == "/") {
      return ""
    } else {
      if (e.toString().toUpperCase() == (window.location.pathname.toString().split("/")[a.length - 1]).toUpperCase()) {
        return ""
      } else {
        return "/" + (window.location.pathname.toString().split("/")[a.length - 1]).toUpperCase()
      }
    }
  }

  const Topmenu = () => {
    return (
      <>
        <div style={{
          height: "70px",
          width: "100%",
          color: topFont == undefined ? outFont : topFont,
          position: "absolute",
          top: "0px",
          zIndex: "10000",
    
          bottom: "0",
          display: "grid",
          gridTemplateColumns: "auto",
          background: buttonsColor
        }}>
          <div style={{
            width: "100%",
            maxWidth: "1024px",
            margin:"auto",
            display: "grid",
            gridTemplateColumns: "auto min-content 200px",
            background: buttonsColor
          }}>
            <div style={{
              height: "70px",
              width: "200px",
              display: "grid",
              gridTemplateColumns: w > 720 ? "70px auto" : "70px auto 70px",
              cursor: "pointer"
            }}>
              {logo}
              <p onClick={() => { window.location.href = "/" }} style={{
                height: "min-content",
                width: "100%",
                margin: "auto",
                textAlign: "left",
                fontWeight: "bold",
                fontSize: "18px",
              }}>{Name} </p>

            </div>
            <div></div>
            <div style={{
              display: "grid", gridTemplateColumns: "auto", height: "min-content", width: "100%", margin: "auto",

            }}>
              <WButton />
            </div>
          </div>

        </div>
     
      </>

    )
  }

  const jef = {
    '/': {
      url: '/',
      el: <Home />
    }
   
  }
  const router = <Routes>
    <>
      {Routers.map((e, i) =>
        <Route path={jef[e].url} element={jef[e].el} />
      )}

    </>
  </Routes>
 
  const Content = (props) => {
    return (<div style={{
      display: "grid",
      gridTemplateRows: (w >= 1024 ? "70px auto" : "100px auto"),
    }}>
      <Topmenu />
      <div className='b' style={{ height: "90vh", display: "block", margin: "auto", marginTop: "0px", maxWidth: "1920px", }}>
        <div style={{ height: "70px" }}></div>
        {router}
      </div>
    </div>);
  }
  return (
    <>
   <div style={{
          width: "100%",
          margin: "auto",
          height: "100vh"
        }}>
          {<Content bool={w < 720} />}
        </div>
      {<Alert />}
    </>
  );
}

const Content = () => {
  return (
    <Connection content={<Main />} />
  )
}

export default Content;