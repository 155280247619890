import React, { useState, useEffect, useRef, useContext } from 'react';
import './home.css';
import ProjectData from '../config';
import { ethers } from 'ethers';
import AppContext from '../framework/helpers/AppContext';
import Timer from '../../src/components/Timer/timer';
import Button from '../components/Launchpad/Button';
import InputBlock from '../components/Launchpad/InputBlock';
const Web3 = require('web3-eth');

let projectName = "Martik"
const {

	buttonsColor,

	Borders,
	outFont,
	modalsColor,

	logoUrl,

	info
} = ProjectData[window.location.host.split('.')[0] != undefined
	? window.location.host.split('.')[0] == "app"
		? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1]
		: window.location.host.split('.')[0] : "Martik"]
const abi = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_contractm",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "own",
				"type": "address"
			},
			{
				"internalType": "uint256[]",
				"name": "data",
				"type": "uint256[]"
			}
		],
		"stateMutability": "payable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address[]",
				"name": "addresses",
				"type": "address[]"
			}
		],
		"name": "_addToWhitelist",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "ref",
				"type": "address"
			}
		],
		"name": "_buy",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "when",
				"type": "uint256"
			}
		],
		"name": "_cancel",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "when",
				"type": "uint256"
			}
		],
		"name": "_claim",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "_contribute",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "when",
				"type": "uint256"
			}
		],
		"name": "_finalize",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "when",
				"type": "uint256"
			}
		],
		"name": "_removeContribute",
		"type": "event"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	},
	{
		"inputs": [],
		"name": "_contract",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "_io",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "_owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "_ownerFac",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "Auth",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "balance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contributors",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ended",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "endTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxbuy",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "minbuy",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "presale_rate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "price",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pricePlus",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "startTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "user_amount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "user_amount_BNB",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]
const Newhome = () => {
	const {
		Tokencontract,
		nSendTX
	} = useContext(AppContext)

	const [selling, setSelling] = useState(0);
	const [contributors, setContributors] = useState(0);
	const [object, setObject] = useState(undefined);
	function nextblock(accountBalancemctTB, d) {
		if (accountBalancemctTB != undefined && accountBalancemctTB != 0) {
			if (accountBalancemctTB.toString().length >= d) {
				return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(2)
			} else {
				let cutin = (d - accountBalancemctTB.toString().length) + 2
				return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(cutin)
			}
		} else {
			return 0.00
		}
	}
	/*const [seconds, setSeconds] = useState(0);
	useEffect(() => {
	  let interval = setInterval(() => {
		setSeconds(seconds => seconds + 1)
		load()
	  }, 1000);
	  return () => clearInterval(interval);
	}, [seconds]);*/
	useEffect(() => {
		load()
	}, [])

	async function load() {
		try {
			const tct = await Tokencontract(abi, "0xaB43E57698efd1bcF62da7044Fb0De0DAEf64820")

			setContributors(await tct.methods.contributors().call())
			setSelling(nextblock(await tct.methods.balance().call(), 18))

			setObject(
				{
					starttime: await tct.methods.startTime().call(),
					endtime: await tct.methods.endTime().call()
				}
			)
		} catch (error) {
			console.log(error)
		}

	}

	const containerStyleA = {
		height: 'min-content',
		width: '100%',
		display: 'grid',
		margin: 'auto',
		paddingTop: "10px",
		paddingBottom: "10px",
		maxWidth: "1280px",
		margin: "auto",
		marginTop: '40px',
	};

	const contentContainerStyleA = {
		height: 'min-content',
		width: '100%',
		display: 'grid',
		margin: 'auto',
		gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
		gap: '15px',
		marginTop: '0px',

	};

	const itemStyle = {
		height: '100px',
		width: '280px',
		borderRadius: "5px",
		padding: '10px 5px',
		margin: 'auto',
		background: modalsColor,
		border: "1px solid " + Borders
	};

	const labelStyle = {
		fontSize: '16px',
		color: outFont,
		textAlign: 'center',
	};

	const valueStyle = {
		fontSize: '25px',
		color: outFont,
		fontWeight: "bold",
		textAlign: 'center',
	};

	const subValueStyle = {
		fontSize: '16px',
		color: outFont,
		textAlign: 'center',
	};

	const containerStyle = {
		height: "min-content",
		width: "100%",
		gap: "10px",
		//maxWidth: "1280px",
		display: "grid",
		gridTemplateColumns: window.innerWidth < 720 ? "auto" : "280px auto", //'repeat(auto-fit, minmax(100px, 1fr))',
		color: outFont,
		borderRadius: "2px",
		margin: "auto",

	};



	const contentContainerStyle = {
		height: "min-content",
		width: "100%",
		display: "grid",
		margin: "auto 0",
		gap: "10px",
		paddingLeft: window.innerWidth < 720 ? "10px" : "0px",
	};

	const headingStyle = {
		height: "min-content",
		textAlign: "left",
		fontSize: "40px",
		fontWeight: "bold",
		margin: "auto 0",

		width: "100%"
	};

	const descriptionStyle = {

		fontSize: "16px",
		fontWeight: "200",
		height: "100%",
		width: "100%",

		margin: "0 auto",
		textAlign: "left",
	};

	const buttonContainerStyle = {
		width: "100%",
		minWidth: "200px",
		display: "grid",
		gridTemplateColumns: "auto 200px",
		gap: "10px",
	};



	const imageContainerStyle = {
		height: "280px",
		width: "280px",
		color: "#fff",
		margin: "auto",
		background: "url(" + logoUrl + ")",
		backgroundSize: "100%",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		display: "grid",
		borderRadius: "360px",
	};
	const SAFE = (props) => {
		return (
			<p style={{ width: "95%", display: "grid", height: "25px", gridTemplateColumns: "auto auto", margin: "auto", borderBottom: "1px solid #ffffff00" }}><span style={{
				textAlign: "left",
				width: "100%",
				fontWeight: "bold",
				color: outFont,
				fontSize: "14px",
				margin: "auto"
			}}>{props.what} </span><span style={{
				textAlign: "right",
				width: "100%",
				fontSize: "14px",
				color: outFont,
				margin: "auto"
			}}>{props.result}</span></p>

		);
	}
	function endedccheck(object) {
		try {
			if (parseInt(Date.parse(new Date()) / 1000) >= parseInt(1712944800)) {
				if (parseInt(Date.parse(new Date()) / 1000) < parseInt(1713070800)) {
					return "Sale live"
				} else {
					return "Ended"
				}
			} else {
				return "Start in "
			}
		} catch (error) {
			return "Waiting status"
		}



	}
	const Content0 = () => {
		const [isIntersecting, setIsIntersecting] = useState(false);
		const [value, setvalue] = useState(0);
		const ref = useRef(null);
		function progress() {
			return ((selling / 250) * 100).toString()
			//  return ((nextblock(object.bal, 18) / nextblock(object.hardcap, 18)) * 100).toString()
		}

		function returnbignumber(value, dec) {
			let a = ['']
			let e = value + ""
			if (e.toString().includes(",")) {
				a = e.toString().split(",")
				e = ethers.utils.parseUnits(a[0] + "." + a[1], dec).toString()
			} else {
				e = ethers.utils.parseUnits(e, dec).toString()
			}

			return e
		}
		function getQuery(query) {
			const search = window.location.search;
			const params = new URLSearchParams(search);
			const foo = params.get(query);
			return foo
		}
		useEffect(() => {

			const observer = new IntersectionObserver(([entry]) => {
				setIsIntersecting(entry.isIntersecting);
			});

			observer.observe(ref.current);
			return () => observer.disconnect();
		}, [isIntersecting]);

		useEffect(() => {
			if (isIntersecting) {
				ref.current.querySelectorAll("div").forEach((el) => {
					el.classList.add("slide-in");
				});
			}
		}, [isIntersecting]);
		return (
			<div ref={ref} style={{
				height: "min-content",
				minHeight: "480px",

				width: "100%",
				display: 'grid'
			}}>
				<div style={{
					maxWidth: "1024px",
					margin: "auto",
					width: "100%",
				}}>

					<div style={containerStyle}>
						<div style={imageContainerStyle}></div>
						<div style={contentContainerStyle}>
							<p style={{ fontSize: "12px" }} >

								<span style={headingStyle}> 		{info.name}   </span>
								<p />
								PRESALE



							</p>

							<div style={descriptionStyle}>
								<br />
								{info.desc}
							</div>




						</div>


					</div>
					<div style={containerStyle}>
						<div style={{ borderRadius: "10px", border: "1px solid" + Borders }}>
							{object != undefined
								&&
								<div style={{ height: "min-content", width: "200px", padding: "15px 0px", margin: "auto" }}>
									<SAFE what={endedccheck()} result={""} />

									<Timer
										endtime={parseInt(Date.parse(new Date()) / 1000) >= parseInt(object.starttime) ? (new Date(parseInt(object.endtime * 1000))).toString() : (new Date(parseInt(object.starttime * 1000))).toString()}
									/>
								</div>

							}
							<div style={buttonContainerStyle}>
							</div>


							<SAFE what={"Sale Type"} result={"Public"} />
							<SAFE what={"Contributors"} result={contributors} />
							<SAFE what={"Minimum buy"} result={`${0.1} ETH`} />
							<SAFE what={"Maximum buy"} result={`${2} ETH`} />
							<SAFE what={"Maximum Cap"} result={`${250} ETH`} />
							{object != undefined && <SAFE what={"Start time"} result={object.starttime != undefined || object.starttime != null ? (new Date(parseInt(object.starttime * 1000))).toLocaleString() : "..."} />}
							{object != undefined && <SAFE what={"End time"} result={object.endtime != undefined || object.endtime != null ? (new Date(parseInt(object.endtime * 1000))).toLocaleString() : "..."} />}
						</div>
						<div style={{ width: "100%", borderRadius: "10px", border: "1px solid" + Borders, padding: "5px" }}>

							<div style={{
								height: "min-content",
								width: "100%",
								margin: "auto",
								display: "grid",
								gap: "5px"
							}}>
								{ /*     <button style={{
                  display: "grid", gridTemplateColumns: "auto", height: "45px", width: "90%", color: "#fff", background: "#00000000", borderRadius: "10px", border: "1px solid #00000000", margin: "auto"
                }}>
                  <Timer endtime={parseInt(Date.parse(new Date()) / 1000) >= parseInt(object.starttime) ? (new Date(parseInt(object.endtime * 1000))).toString() : (new Date(parseInt(object.starttime * 1000))).toString()} />
              </button>*/}
								<div style={{
									position: " relative",
									width: "95%",
									height: "20px",
									backgroundColor: Borders,
									margin: "auto",
									marginTop: "30px",
									marginBottom: "2px",
									borderRadius: "5px",

								}}>
									<div style={{
										position: "absolute",
										width: parseFloat(progress()) > 100 ? "100%" : progress() + "%",
										height: "100%",
										backgroundColor: buttonsColor,
										borderRadius: "5px"
									}}></div>
									{parseFloat(progress()) > 100 ? <div style={{
										position: "absolute",
										width: "20%",
										height: "100%",
										backgroundColor: buttonsColor,
										borderRadius: "5px"
									}}></div> : <></>}
								</div>
								<p style={{ width: "95%", display: "grid", gridTemplateColumns: "auto auto", margin: "auto", marginTop: "0px" }}><span style={{
									textAlign: "left",
									width: "100%",

									color: outFont,
									fontSize: "12px",

								}}>{`${selling} ETH`}
								</span>
									<span style={{
										textAlign: "right",
										width: "100%",
										fontSize: "12px",
										color: outFont,
									}}>
										{`${250} ETH`}
									</span>
								</p>




								<p></p>
							</div>


							<div style={buttonContainerStyle}>
								<InputBlock title={""}
									place={"0"}
									variavel={value}
									setvariavel={setvalue}
									desc={""} />
								<Button func={() => {
									nSendTX(
										"0xaB43E57698efd1bcF62da7044Fb0De0DAEf64820",
										abi,
										0,
										"_buy",
										returnbignumber(value, 18),
										0,
										returnbignumber(value, 18),
										'0x0000000000000000000000000000000000000000'
									).then(a => {
										load()
									}).catch(e => {
										//console.log(e)
									})
									// window.open(token.buyon)
								}} text={"Buy "} />
							</div>
						</div>


					</div>
				</div>
			</div >
		)
	}



	//('refFrom') == null
	return (
		<div>
			<Content0 />
			<p style={{ height: "100px" }}></p>

		</div>
	);

}

export default Newhome;